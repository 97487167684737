@mixin flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@mixin flex-col {
  display: flex;
  flex-direction: column;
}

@mixin radius-all {
  border-radius: 4px;
}

@mixin radius-left {
  border-radius: 4px 0px 0px 4px;
}

@mixin radius-right {
  border-radius: 0px 4px 4px 0px;

}

@mixin selected-primitive($shadow-color, $border-color) {
  &::after {
    content: " ";

    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    // -webkit-transform: translate(-50%, -50%);
    // -moz-transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
    background-color: $shadow-color;
  }
}

@mixin errorred-primitive() {
  &::after {
    content: " ";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    // left: 50%;
    // top: 50%;
    // -webkit-transform: translate(-50%, -50%);
    // -moz-transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
    border: 1px solid red;
    border-radius: 3px;

  }

}

@mixin selected-frame($border-color) {
  border: 1px solid $border-color;
}

@mixin disable-animation {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
