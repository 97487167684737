@import 'src/scss/app.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  font-family: Roboto;
}

.App {
  height: 100vh;
  min-height: 100vh;
  background-color: #E4E8EC;
  display: flex;
  flex-direction: column;

  &__content {
    padding: 9px 20px;
    padding-bottom: 18px;
    // flex-grow: 1;
    height: calc(100vh - 57px);
  }
}

.react-list-editable ul {
  padding: 0;
}

.react-list-editable ul li {
  display: flex;
  align-items: center;
}

.react-list-editable .new-input-field {
  margin: 0 10px;
  width: 100%;
}

.react-list-editable .delete-btn {
  flex-shrink: 0;
}

.no-select {
  user-select: none !important;
}
.no-select * {
  user-select: none !important;
}

.MuiDrawer-root {
  height: calc(100vh - 48px) !important;
}