.AdminContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &__space {
        width: 100%;
        height: 40px;
    }

    &__outer {
        width: 100%;
        height: 100%;
    }
}